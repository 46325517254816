import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/css/common.scss"
import "@/assets/css/zlc.scss"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)


import tool from "@/utils/tool";
Vue.prototype.$tool = tool;

import AMapLoader from "@amap/amap-jsapi-loader";
import {AMapKey} from "@/config/config";
AMapLoader.load({
  'key': AMapKey,
  'version': '2.0',   // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
  'plugins': ['AMap.Scale','AMap.GeoJSON']           // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
}).then((AMap) => {
  Vue.use(AMap)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
