import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUs from '../views/AboutUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({y: 0}), //滚动到顶端
})

export default router
