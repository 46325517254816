<template>
  <div class="__section-main__">
    <div class="__container__">
      <div v-if="aboutData" class="section-01">
        <div class="left-cont">
          <div class="title">{{ aboutData.title.split('--')[0] }}</div>
          <div class="sub-title">
            <div>{{ aboutData.title.split('--')[1] }}</div>
          </div>
        </div>
        <div class="right-cont">
          <div v-for="(item,index) in aboutData.description.split('\n')" :key="index" class="desc">{{ item }}</div>

        </div>
      </div>

      <div v-if="productData" :style="{backgroundImage: `url(${productData.resource})`}" class="section-02">
        <div class="title">{{ productData.title }}</div>
        <div class="desc">
          {{ productData.description }}
        </div>
      </div>


      <template v-if="isMobile">
        <div v-for="item in articleList" :key="item.id" :style="{backgroundImage: `url(${item.resource})`}" class="section-02">
          <div class="title">{{ item.title }}</div>
          <div class="desc">
            {{ item.description }}
          </div>
        </div>
      </template>
      <template v-if="!isMobile">
        <div v-for="item in articleList" :key="item.id" class="section-03">
          <div class="title">{{ item.title }}</div>
          <div v-for="(desc,descIndex) in item.description.split('\n')" :key="descIndex" class="desc">{{ desc }}</div>
          <div :style="{backgroundImage: `url(${item.resource})`}" class="image-cont"></div>
        </div>
      </template>


      <div style="height: 10rem"></div>
    </div>
  </div>

</template>

<script>
import {getArticles} from "@/request/api";
import {mapState} from "vuex";

export default {
  name: "HomeView",
  data() {
    return {
      aboutData: null,
      productData: null,
      articleList: [],
    }
  },
  computed: {
    ...mapState(['isMobile'])
  },
  mounted() {
    this.getAboutData();
    this.getProductData();
    this.getArticleList();
  },
  methods: {
    async getAboutData() {
      let res = await getArticles({classify_id: 1});
      this.aboutData = res.data;
    },
    async getProductData() {
      let res = await getArticles({classify_id: 6});
      this.productData = res.data;
    },
    async getArticleList() {
      let articleList = [];
      let res;
      res = await getArticles({classify_id: 2});
      articleList.push(res.data);
      res = await getArticles({classify_id: 3});
      articleList.push(res.data);
      res = await getArticles({classify_id: 4});
      articleList.push(res.data);
      res = await getArticles({classify_id: 5});
      articleList.push(res.data);
      this.articleList = articleList;
    }
  }
}
</script>

<style lang="scss" scoped>
.section-01 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 16.5rem 0 10rem;

  .left-cont {
    width: 50%;

    .title {
      font-size: 3.6rem;
      font-weight: bold;
    }

    .sub-title {
      margin-top: 10rem;
      margin-bottom: 5rem;

      & > div {
        font-size: 6rem;
        font-weight: bold;
      }
    }
  }

  .right-cont {
    width: 75rem;

    .desc {
      font-size: 2.2rem;
      line-height: 2.4;
      margin-top: 5rem;
      text-indent: 4.4rem;
    }
  }
}

.section-02 {
  width: 100%;
  height: 82rem;
  padding: 8rem 7rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .title {
    width: 65rem;
    font-size: 4rem;
    color: #FFFFFF;
    font-weight: bold;
  }

  .desc {
    width: 65rem;
    font-size: 2.2rem;
    color: #FFFFFF;
    line-height: 2.4;
    margin-top: 4rem;
  }
}

.section-03 {
  padding: 14rem 0 0 0;

  .title {
    width: 85%;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 6rem;
  }

  .desc {
    width: 85%;
    font-size: 2.2rem;
    line-height: 2.4;
    text-indent: 4.4rem;
  }

  .image-cont {
    margin-top: 10rem;
    width: 100%;
    height: 72rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 1024px) {
  .section-01 {
    flex-direction: column;

    .left-cont {
      width: 100%;
    }

    .right-cont {
      margin-top: 10rem;
      width: 100%;
    }
  }
}
</style>
