import request from "@/utils/request"

// 获取首页公共数据
export const getHomeData = (data) => {
  return request.post("/pc/home", data)
}

// 获取文章数据
export const getArticles = (data) => {
  return request.post("/pc/articles", data)
}

// 留言
export const createMessage = (data) => {
  return request.post("/pc/message", data)
}

// 留言
export const getCompanyData = (data) => {
  return request.post("/pc/getCompanyFind", data)
}

// 获取跳转小程序跳转链接
export const getSchemeUrl = (data) => {
  return request.post("/api/accessToken", data)
}



