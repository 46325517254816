import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    navigationList: [
      {name: '首页', path: '/'},
      {name: '关于膜宝宝', path: '/aboutUs'},
    ],
  },
  getters: {
  },
  mutations: {
    setIsMobile: (state, payload) => {
      state.isMobile = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
