import {IMAGE_PATH} from "@/config/config.js"

const tool = {

    deleteEmptyProperty(object) {
        for (let i in object) {
            let value = object[i];
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    if (value.length == 0) {
                        delete object[i];
                        continue;
                    }
                }
                this.deleteEmptyProperty(value);
                if (this.isEmpty(value)) {
                    delete object[i];
                }
            } else {
                if (value === '' || value === null || value === undefined) {
                    delete object[i];
                }
            }
        }
        return object;
    },
    isEmpty(object) {
        for (let name in object) {
            return false;
        }
        return true;
    },
    getTimeStamp(data = '') {
        let timeStamp = data ? (new Date(data.replace(/-/g, '/')).getTime()) : (new Date().getTime());
        return timeStamp;
    },
    // 验证身份证号码
    verifyIdcard(data) {
        let reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        return reg.test(data);
    },
    // 验证身份证号码
    verifyPhone(data) {
        let reg = /^1[3-9]{1}[0-9]{9}$/;
        return reg.test(data);
    },


    getImage(data) {
        // return data
        if (data.indexOf(IMAGE_PATH) !== -1) {
            return data
        } else {
            return IMAGE_PATH + data
        }
    },

    numberToDouble(number) {
        return number < 10 ? '0' + number : '' + number
    },

    getTimeStr(timeStamp) {
        function toDouble(n) {
            return n < 10 ? '0' + n : '' + n;
        }

        let date = new Date(timeStamp);
        let year = date.getFullYear();
        let mouth = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        let time = year + '-' + toDouble(mouth) + '-' + toDouble(day) + ' ' + toDouble(hour) + ':' + toDouble(minute) + ':' + toDouble(second);
        return time;
    },


}

export default tool;
