<template>
  <div class="custom-footer">
    <div class="cx-fex cx-fex-itemsc" style="width: 100%">
      <div class="nav-list">
        <router-link v-for="(item, index) in navigationList" :key="index" :to="item.path" class="nav-item" tag="div">{{ item.name }}</router-link>
      </div>

      <div class="contact">
        <div class="tel">24小时客服热线：{{ companyData.phone }}</div>
        <!--        <div v-if="!isMobile" class="image-cont">-->
        <!--          <img alt="" src="@/assets/images/wx-icon.png"/>-->
        <!--          <div class="code-popup">-->
        <!--            <img alt="" src="@/assets/images/mp-code.jpg"/>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="cx-fex-c cx-fex-itemsc cx-fex-column" style="width: 100%;margin-top: 4rem">
      <!--      <div class="agreement-cont">-->
      <!--        <div>隐私条款</div>-->
      <!--        <div>法律声明</div>-->
      <!--      </div>-->
      <div class="copyright">
        {{ companyData.name }}
        <a :href="companyData.filings_link" target="_blank">{{ companyData.filings }}</a>

      </div>
    </div>


    <div class="qr-code-cont">
      <div class="image-cont" @click="jumpMp()">
        <img alt="" src="@/assets/images/wx-icon.png"/>
        <div class="code-popup">
          <img alt="" src="@/assets/images/mp-code.jpg"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getCompanyData, getSchemeUrl} from "@/request/api";

export default {
  name: "customFooter",
  props: {
    companyData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState(['navigationList', 'isMobile']),
  },
  mounted() {
  },
  methods: {
    async jumpMp() {
      if (this.isMobile) {
        let res = await getSchemeUrl();
        window.open(res.data.openlink)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-cont {
  position: fixed;
  right: 0;
  bottom: 30rem;
  z-index: 999;

  .image-cont {
    width: 5rem;
    height: 5rem;
    border: .1rem solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: relative;
    background-color: #FFFFFF;

    img {
      width: 2.5rem;
      height: 2rem;
    }

    &:hover {
      .code-popup {
        display: block;
      }
    }

    .code-popup {
      border-radius: 1rem;
      display: none;
      position: absolute;
      right: 5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 20rem;
      height: 20rem;
      background-color: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 1.5rem;

      img {
        width: 100%;
        height: 100%;
      }

    }
  }


}

.custom-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 23rem;
  border-top: .1rem solid #e5e5e5;
  padding: 0 17rem;

  .nav-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .nav-item {
      font-size: 2.2rem;
      margin-right: 10rem;
      cursor: pointer;
    }
  }

  .contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tel {
      font-size: 2.2rem;
    }

    .image-cont {
      width: 4rem;
      height: 4rem;
      border: .1rem solid #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 13rem;
      border-radius: 100%;
      position: relative;

      img {
        width: 2.5rem;
        height: 2rem;
      }

      &:hover {
        .code-popup {
          display: block;
        }
      }

      .code-popup {
        border-radius: 1rem;
        display: none;
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width: 20rem;
        height: 20rem;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }

      }
    }
  }

  .agreement-cont {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      color: #B2B2B2;
      font-size: 2.2rem;
      line-height: 1;
      padding: 0 2rem;
      border-right: .1rem solid #b2b2b2;

      &:last-child {
        border-right: none;
      }
    }
  }

  .copyright {
    font-size: 2.2rem;
    color: #B2B2B2;
    margin-top: 1.5rem;

    a {
      font-size: 2.2rem;
      color: #B2B2B2;
    }
  }
}

@media screen and (max-width: 1024px) {
  .qr-code-cont {
    .image-cont {
      width: 10rem;
      height: 10rem;

      img {
        width: 7.5rem;
        height: 6rem;
      }

      &:hover {
        .code-popup {
          display: none;
        }
      }

      .code-popup {
        right: 10rem;
        width: 40rem;
        height: 40rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .custom-footer {
    height: 30rem;
    padding: 0 5rem;

    .contact {
      .image-cont {
        margin-left: 7rem;
      }
    }

    .copyright {
      margin-top: 3rem;
      text-align: center;
    }
  }
}

</style>
