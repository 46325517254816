export const AMapKey = '5c97c09046e25841a148ff049731b788';


// //测试
// export const BASE_URL = 'https://mbbht.jiluoing.com';
// export const IMAGE_PATH = 'https://mbbht.jiluoing.com/storage/';

// 正式域名
export const BASE_URL = 'https://admin.mobaobao.net';
export const IMAGE_PATH = 'https://mobaobao-1301389725.cos.ap-shanghai.myqcloud.com/';
