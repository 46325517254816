<template>
  <div class="__section-main__">
    <div class="__container__">
      <div class="company-cont" v-if="aboutData">
        <div class="title">{{ aboutData.title }}</div>
        <div class="desc">
          {{aboutData.description}}
        </div>
        <div :style="{backgroundImage: `url(${aboutData.resource})`}" class="image-cont"></div>
      </div>

      <div class="contact-cont" v-if="companyData">
        <div class="title">如有需求，请与我们取得联系</div>

        <div class="contact-list">
          <div v-for="(item,index) in contactList" :key="index" class="contact-item">
            <img :src="item.icon" alt=""/>
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>

      <div class="form-cont">
        <div class="cx-fex cx-fex-itemsc header-cont">
          <div class="form-item">
            <div class="label">姓名</div>
            <input v-model="formData.name" placeholder="请输入姓名" type="text"/>
          </div>
          <div class="form-item">
            <div class="label">手机号</div>
            <input v-model="formData.mobile" placeholder="请输入手机号" type="number"/>
          </div>
          <div class="form-item">
            <div class="label">邮箱</div>
            <input v-model="formData.email" placeholder="请输入邮箱" type="text"/>
          </div>
        </div>
        <div class="form-item">
          <div class="label">留言内容</div>
          <textarea v-model="formData.content" placeholder="请输入留言内容"></textarea>
        </div>
        <div class="form-item">
          <div class="label">留言内容</div>
          <div class="cx-fex-l fex-itemsc">
            <input v-model="writeCode" placeholder="请输入验证码" style="width: 35rem" type="text"/>
            <div id="checkCode" class="code" @click="createCode(4)">{{ code }}</div>
          </div>
        </div>
        <div class="form-item">
          <div class="submit-btn" @click="handleSubmit()">提交</div>
        </div>
      </div>


    </div>
    <div id="container" ref="myMap" class="map"></div>
  </div>
</template>

<script>
import {createMessage, getCompanyData, getArticles} from "@/request/api";
import markerPointImage from "@/assets/images/marker-point.png";
let map = {};
export default {
  name: "AboutUs",
  data() {
    return {
      contactList: [
        {icon: require("@/assets/images/contact-icon-01.png"), title: '地址', content: ''},
        {icon: require("@/assets/images/contact-icon-02.png"), title: '联系电话', content: ''},
        {icon: require("@/assets/images/contact-icon-03.png"), title: 'E-mail', content: ''},
        {icon: require("@/assets/images/contact-icon-04.png"), title: '传真', content: ''},
      ],
      code: '',
      writeCode: '',
      formData: {
        name: '',
        mobile: '',
        email: '',
        content: '',
      },
      latitude:'',
      longitude:'',
      companyData: null,
      aboutData:null,
    }
  },
  async mounted() {
    this.createCode(4);
    await this.getAboutData();
    await this.getCompanyData();
    setTimeout(() => {
      this.createMap();
    }, 1000)

  },
  methods: {
    async getAboutData(){
      let res = await getArticles({classify_id:1});
      this.aboutData = res.data;
    },
    async getCompanyData() {
      let {data} = await getCompanyData()
      this.companyData = data;
      this.contactList[0].content = data.address;
      this.contactList[1].content = data.phone;
      this.contactList[2].content = data.email;
      this.contactList[3].content = data.fax;
      this.latitude = data.lat;
      this.longitude = data.long;
    },
    async handleSubmit() {
      if (this.checkFormData()) {
        await createMessage(this.formData);
        this.$message.success('留言成功');
        this.formData = {
          name: '',
          mobile: '',
          email: '',
          content: '',
        }
        this.writeCode = '';
        this.createCode(4)
      }
    },
    checkFormData() {
      if (!this.formData.name) {
        this.$message.error('请输入姓名');
        return false;
      } else if (!this.$tool.verifyPhone(this.formData.mobile)) {
        this.$message.error('请输入正确的手机号');
        return false;
      } else if (!this.formData.content) {
        this.$message.error('请输入留言内容');
        return false;
      } else if (!this.writeCode) {
        this.$message.error('请输入验证码');
        return false;
      } else if (this.writeCode.toLowerCase() !== this.code.toLowerCase()) {
        this.$message.error('验证码错误');
        this.createCode(4);
        return false;
      }
      return true;
    },
    createMap() {
      //填入地图容器div的id
      map = new AMap.Map('container', {
        zoom: 14,	//设置地图的缩放级别
        zooms: [8, 20],	//设置地图的缩放级别区间
        center: [this.longitude, this.latitude],	//设置地图中心点坐标
        features: ['road', 'point']	//设置地图的底图元素，缺少则显示全部元素，bg区域面、point兴趣点、road道路及道路标注、building建筑物
      });

      // let marker = new AMap.Marker({
      //   position: [this.longitude, this.latitude],   // 经纬度
      //   //创建一个自定义图标实例
      //   icon: new AMap.Icon({
      //     image: markerPointImage,  // 自定义图像的url
      //   }),
      //   title: this.companyData.name,	//当鼠标悬浮在标记上时，会显示该内容
      // });
      // map.add(marker);

      var labelMarker = new AMap.LabelMarker({
        name: '标注1',	// 此属性并不会绘制在地图上，只是作为该点的id使用
        position: [this.longitude, this.latitude],
        opacity:1,	//标注层透明度
        icon: {
          type: 'image',	// 图标类型，现阶段只支持 image 类型
          image: markerPointImage,	// 图片 url
          size: [30, 30],	// 图片尺寸
          anchor: 'center',		// 图片相对 position 的锚点，默认为 bottom-center
        },
        text: {
          content: this.companyData.name,	// 要展示的文字内容
          direction: 'right',	// 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          offset: [-110, -35],	// 在 direction 基础上的偏移量
          style: {
            fontSize: 16,	// 字体大小
            fillColor: '#ff4002',	// 字体颜色
            strokeWidth: 2,	// 描边宽度
            fontWeight: 'bold',	// 字体粗细
          },
        }
      });
      //将点标记添加至地图
      map.add(labelMarker)
    },
    createCode(length) {
      let code = "";
      let codeLength = parseInt(length); //验证码的长度
      //所有候选组成验证码的字符，当然也可以用中文的
      let codeChars = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
      ];
      //循环组成验证码的字符串
      for (let i = 0; i < codeLength; i++) {
        //获取随机验证码下标
        let charNum = Math.floor(Math.random() * 62);
        //组合成指定字符验证码
        code += codeChars[charNum];
      }
      this.code = code;
    },
  }
}
</script>

<style lang="scss" scoped>
.company-cont {
  padding-top: 10rem;

  .title {
    font-size: 4.8rem;
    text-align: center;
    margin-bottom: 4rem;
  }

  .desc {
    text-align: center;
    font-size: 2.2rem;
    line-height: 2.4;
    color: #333333;
  }

  .image-cont {
    width: 100%;
    height: 82rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 7rem;
  }
}

.contact-cont {
  padding-top: 17rem;

  .title {
    font-size: 4.8rem;
    font-weight: 500;
    text-align: center;
  }

  .contact-list {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    margin-top: 4rem;

    .contact-item {
      width: 33rem;
      margin: 5rem 0;
      //height: 35rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 5rem 4rem 7rem;
      background-color: #F7F7F7;

      img {
        width: 8rem;
        height: 8rem;
      }

      .title, .content {
        font-size: 2rem;
        line-height: 2.5;
      }

      .title {
        margin-top: 2rem;
      }
    }
  }
}

.form-cont {
  .form-item {
    width: 100%;
    padding: 2.5rem 0;

    .label {
      font-size: 2rem;
      color: #666666;
      margin-bottom: 2.5rem;
    }

    input {
      width: 100%;
      padding: 0 2rem;
      height: 6rem;
      line-height: 6rem;
      outline: none;
      border: .1rem solid #DCDCDC;
      border-radius: .5rem;
      font-size: 2rem;
    }

    textarea {
      width: 100%;
      padding: 2rem;
      height: 30rem;
      outline: none;
      border: .1rem solid #DCDCDC;
      border-radius: .5rem;
      resize: none;
      font-size: 2rem;
    }
  }

  .header-cont {
    .form-item {
      width: 48rem !important;
    }
  }

  .submit-btn {
    width: 23.5rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    border-radius: 3rem;
    background-color: #0070C0;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.map {
  margin-top: 10rem;
  width: 100%;
  height: 80rem;
}

.code {
  margin-left: 2rem;
  font-family: Arial;
  font-style: italic;
  color: blue;
  font-size: 3rem;
  border: 0;
  padding: .2rem .3rem;
  letter-spacing: .4rem;
  font-weight: bolder;
  float: left;
  cursor: pointer;
  width: 15rem;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  vertical-align: middle;
  background-color: #D8B7E3;
  border-radius: .4rem;
}

@media screen and (max-width: 1024px) {
  .contact-cont {
    .contact-list {
      flex-wrap: wrap;

      .contact-item {
        width: 45%;
        margin: 4rem 0;
      }
    }
  }

  .form-cont {
    .header-cont {
      .form-item {
        width: 100% !important;
      }
    }
  }

}
</style>
