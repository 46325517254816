<template>
  <div :style="{height:isMobile ? '60vw' : '100vh'}" class="banner">
    <el-carousel :height="isMobile ? '60vw' : '100vh'" arrow="hover">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <div :style="{backgroundImage: `url(${$tool.getImage(item.resource)})`}" class="banner-item">
          <div :style="{top:isMobile ? '65%' : '50%'}" class="word-cont">
            <div class="title">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
            <a :href="item.link" class="more-btn" target="_blank">了解更多</a>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "customBanner",
  computed: {
    ...mapState(['isMobile']),
  },
  props: {
    bannerList: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss" scoped>

.banner {
  width: 100%;
  //height: 100vh;

  .banner-item {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .word-cont {
      position: absolute;
      left: 17rem;
      top: 50%;
      transform: translateY(-65%);

      .title {
        font-size: 9rem;
        color: #FFFFFF;
        font-weight: bold;
        line-height: 1;
      }

      .desc {
        font-size: 3rem;
        color: #FFFFFF;
        margin-top: 4rem;
        line-height: 2;
      }

      .more-btn {
        margin-top: 6rem;
        display: block;
        width: 20rem;
        height: 8rem;
        border-radius: 4rem;
        border: .1rem solid #FFFFFF;
        color: #FFFFFF;
        text-align: center;
        line-height: 8rem;
        font-size: 2.4rem;
      }
    }

  }
}
</style>
<style lang="scss">
.el-carousel__button {
  width: 9rem;
  height: .5rem;
}

//@media screen and (max-width: 1024px){
//  .el-carousel__button {
//    width: 9rem;
//    height: .5rem;
//  }
//}
</style>
