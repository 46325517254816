<template>
  <div id="app">
    <custom-header :companyData="companyData"></custom-header>
    <custom-banner :bannerList="bannerList"></custom-banner>
    <router-view/>
    <custom-footer :companyData="companyData"></custom-footer>
  </div>
</template>

<script>
import {remFn} from "@/utils/rem";
import CustomHeader from "@/components/customHeader";
import CustomBanner from "@/components/customBanner";
import CustomFooter from "@/components/customFooter";
import {getCompanyData, getHomeData} from "@/request/api";

export default {
  components: {CustomFooter, CustomBanner, CustomHeader},
  data() {
    return {
      bannerList:[],
      companyData:{},
    }
  },
  mounted() {
    this.reloadRem();
    window.addEventListener("resize", this.reloadRem, {passive: false});
    this.getHomeData();
    this.getCompanyData();
  },
  methods:{
    async getCompanyData() {
      let res = await getCompanyData();
      this.companyData = res.data;
    },
    async getHomeData() {
      let res = await getHomeData();
      this.bannerList = res.data.banner;

    },
    reloadRem() {
      let windowWidth = document.documentElement.getBoundingClientRect().width;
      if (windowWidth <= 750) {
        this.$store.commit('setIsMobile', true);
        remFn(968, 1024)
      } else if (windowWidth < 1024 && windowWidth > 750) {
        this.$store.commit('setIsMobile', true);
        remFn(1920, 1920)
      } else {
        this.$store.commit('setIsMobile', false);
        remFn(1920, 3840)
      }
    },
  }
}
</script>

<style lang="scss">

</style>
