<template>
  <div class="custom-header">
    <div :class="{active:(scrollTop >= 10 )}" class="header-cont">
      <div class="image-cont">
        <img :src="scrollTop >= 10 ? companyData.z_logo:companyData.f_logo" alt=""/>
      </div>
      <div class="nav-list">
        <router-link v-for="(item, index) in navigationList" :key="index" :class="{active: $route.path === item.path}" :to="item.path" class="nav-item" tag="div">
          {{ item.name }}
        </router-link>
      </div>

      <a :href="isMobile ? 'https://m.tb.cn/h.5MMGDKi' : 'https://shop265567402.taobao.com/?spm=a230r.7195193.1997079397.2.2a347221Lh2aud'" class="right-btn"
         target="_blank">
        <img alt="" class="btn-image-01" src="@/assets/images/taobao-icon.png"/>
        <div class="name">访问淘宝店铺</div>
        <img alt="" class="btn-image-02" src="@/assets/images/arrow-right.png"/>

      </a>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "customHeader",
  data() {
    return {
      scrollTop: 0,
      logo: require("@/assets/images/logo.png"),
      logoActive: require("@/assets/images/logo-active.png"),
    }
  },
  props: {
    companyData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState(['navigationList', 'isMobile']),
  },
  mounted() {
    this.listenToScrollTop();
  },
  methods: {
    listenToScrollTop() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let that = this;
      window.addEventListener('scroll', function (e) {
        that.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 12rem;
  border-bottom: 1px solid #ffffff;

  .header-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6rem 0 16rem;

    &.active {
      background-color: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

      .nav-list {
        .nav-item {
          color: #333333;
        }
      }

      .right-btn {
        border: .1rem solid #1C82B8;
        background-color: #1C82B8;
      }
    }

    .image-cont {
      width: 18.9rem;
      height: 6.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav-list {
      display: flex;
      justify-content: center;
      align-items: center;

      .nav-item {
        font-size: 2.2rem;
        color: #FFFFFF;
        line-height: 11.8rem;
        border-bottom: .4rem solid transparent;
        margin: 0 5rem;
        cursor: pointer;
        transition: all .5s;

        &.active {
          border-color: #2989BB;
          font-weight: 600;
        }
      }
    }

    .right-btn {
      width: 28rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: .1rem solid #FFFFFF;
      transition: all .5s;

      .btn-image-01 {
        width: 3.6rem;
        height: 3.6rem;
      }

      .name {
        font-size: 2.2rem;
        color: #ffffff;
        margin: 0 3.2rem 0 1.4rem;
        transition: all .5s;
      }

      .btn-image-02 {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .custom-header {
    .header-cont {
      padding: 0 5rem 0 5rem;
    }
  }
}
</style>
